import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import zb_logo from 'assets/images/logo4.png';
import { useHistory } from 'react-router-dom';
import {getCurrentBreadcrumb} from 'utils/util';
import { Menu,Icon } from 'antd';
import { merchantGetMerLogo } from '../reduxs/common'
import {delBillAddress, getBillAddressList} from "../routes/FinancialCenter/InvoiceCenter/BillAddress/reducer";

const { SubMenu } = Menu;
let indexIMg = 1;

function SiderPage(props) {

  const [openKeys,setOpenKeys] = useState([]);
  const [logo,setlogo] = useState("");
  const [selectKeys,setSelectKeys] = useState([]);

  let history = useHistory();

  const handleCancel = () => {
    if (indexIMg === 1) {
      const {actionPromise} = props.merchantGetMerLogo();
      actionPromise.then(res => {
          if (res.data) {
            setlogo(res.data)
          } else {
            setlogo(zb_logo)
          }
      })
    }
    indexIMg++
  }

  useEffect(() => {
    setOpenKeys(getCurrentBreadcrumb(props.location.pathname,props.menuList).initOpenKeys);
    setSelectKeys(getCurrentBreadcrumb(props.location.pathname,props.menuList).selectedKeyDeep);
  },[props.location.pathname,props.menuList])

  const createMenus = (_menus) => {
    return _menus.reduce((pre,item) => {
      if(item.children && item.children.length){
        return [
          ...pre,
          <SubMenu key={item.key + ''} title={
            item.icon ?
            <div className="menu_icon_b">
              <Icon onClick={handleCancel()} type={item.icon} className="menu_icon" />
              {item.menu}
            </div>
            : item.menu
          }>
            {createMenus(item.children)}
          </SubMenu>
        ]
      }else{
        return [
          ...pre,
          <Menu.Item key={item.key + ''} icon={item.icon ? <Icon type={item.icon} /> : null} onClick={() => skipRoute(item.route)}>
            {
              item.icon ?
              <div className="menu_icon_b">
                <Icon type={item.icon} className="menu_icon" />
                {item.menu}
              </div>
              : item.menu
            }
          </Menu.Item>
        ]
      }
    },[]);
  }

  const skipRoute = (route) => {
    history.replace(route);
  }

  const openChange = (key) => {
    setOpenKeys(key);
  }

  const selectChange = (e) => {
    setSelectKeys(e.selectedKeys);
  }

  return (
    <div className="sider_container">
      <div className="company_logo_c">
        {
          logo !== "" ?
            <img src={logo} className="company_logo_icon" alt="" />
            : ''
        }
      </div>
      <div className="sider_menu_c">
        {props.menuList && (props.menuList).length > 0 ? 
        <Menu 
          theme="light" 
          mode="inline"
          openKeys={openKeys}
          selectedKeys={selectKeys}
          // defaultOpenKeys={getCurrentBreadcrumb(props.location.pathname,props.menuList).initOpenKeys}
          // defaultSelectedKeys={getCurrentBreadcrumb(props.location.pathname,props.menuList).selectedKeyDeep}
          onOpenChange={openChange}
          onSelect={selectChange}
        >
          {createMenus(props.menuList)}
        </Menu>: null}
      </div>
    </div>
  )
}

export default connect(state => ({
}),{
  merchantGetMerLogo,
})(SiderPage);

// export default SiderPage;