import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {getMenuList} from 'reduxs/common';
import { Layout } from 'antd';
import SiderPage from './Sider';
import HeaderPage from './Header';
import {getBreadcrumbList} from 'utils/util';
import './index.less';

const { Header, Content, Sider } = Layout;

function MainLayout (props){

  useEffect(() => {
    props.getMenuList();
  },[]);
  
  // useEffect(() => {

  //   localStorage.setItem('menuList', JSON.stringify(props.apiMenuList));

  // },[props.apiMenuList]);

  return (
    <div className="mainlayout_page">
      <Layout>
        <Sider
          theme="light"
          style={{
            overflowY: 'overlay',
            overflowX: 'hidden',
            height: '100%',
            position: 'fixed',
            left: 0,
          }}
          width={240}
        >
          <SiderPage {...props} menuList={JSON.parse(localStorage.getItem("menuList")) || []}></SiderPage>
        </Sider>
        <Layout style={{ marginLeft: 240 }}>
          <Header>
            <HeaderPage {...props} menuList={JSON.parse(localStorage.getItem("menuList")) || []}></HeaderPage>
          </Header>
          <Content>
            <div className="layout_content">
              {props.children}
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  )
}

export default connect(state => ({
  apiMenuList: state.common.menuList
}),{
  getMenuList
})(MainLayout);
